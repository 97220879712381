import React, { useEffect, useState } from 'react';
import ring from './noun-one-ring-1572086.svg';
import backgroundImage from './conner-bowe-riw_BZvlMcI-unsplash.jpg';
import './App.css';

const App = () => {
  const [quotation, setQuotation] = useState({
    quotation: "",
    author: ""
  });
  
  useEffect(() => {
    if (quotation.quotation.length === 0) {
      generateRandomQuotation()
    }
  });

  const quotations = [
    {
      quotation: "Not all those who wander are lost.",
      author: "Bilbo Baggins"
    },
    {
      quotation: "All’s well that ends better.",
      author: "Hamfast Gamgee"
    },
    {
      quotation: "Deeds will not be less valiant because they are unpraised.",
      author: "Aragorn"
    },
    {
      quotation: "Even the smallest person can change the course of the future.",
      author: "Galadriel"
    },
    {
      quotation: "You step into the road, and if you don’t keep your feet, there is no knowing where you might be swept off to.",
      author: "J.R.R. Tolkien"
    },
    {
      quotation: "Let him not vow to walk in the dark, who has not seen the nightfall.",
      author: "Elrond"
    },
    {
      quotation: "It’s the job that’s never started as takes longest to finish.",
      author: "Sam Gamgee"
    },
    {
      quotation: "Your time will come. You will face the same Evil, and you will defeat it.",
      author: "Arwen"
    },
    {
      quotation: "If more of us valued food and cheer and song above hoarded gold, it would be a merrier world.",
      author: "Thorin Oakenshield"
    },
    {
      quotation: "A hunted man sometimes wearies of distrust and longs for friendship.",
      author: "Aragorn"
    },
    {
      quotation: "It is useless to meet revenge with revenge: it will heal nothing.",
      author: "Frodo"
    },
    {
      quotation: "But do not despise the lore that has come down from distant years; for oft it may chance that old wives keep in memory word of things that once were needful for the wise to know.",
      author: "Celeborn"
    },
    {
      quotation: "This day does not belong to one man but to all. Let us together rebuild this world that we may share in the days of peace.",
      author: "Aragorn"
    },
    {
      quotation: "Despair is only for those who see the end beyond all doubt. We do not.",
      author: "Gandalf"
    },
    {
      quotation: "Memory is not what the heart desires. That is only a mirror.",
      author: "Gimli"
    },
  ];

  const generateRandomQuotation = () => {
    const random = Math.floor(Math.random() * quotations.length);
    setQuotation({
      quotation: quotations[random].quotation,
      author: quotations[random].author,
    })
  };


  return (
    <div className="App" style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className="App-bg">
        <h1>One App To Rule Them All</h1>
        <div className="blockquote">
          <blockquote>
            <p>{quotation.quotation}</p>
            <footer>
              <cite>- {quotation.author}</cite>
            </footer>
          </blockquote>
        </div>
        <div onClick={generateRandomQuotation} >
          <img src={ring} className="ring" alt="One Ring To Rule Them All" />
        </div>
        <div className="footer">
          <p>Photo by <a href="https://unsplash.com/@connerbowe?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">conner bowe</a> on <a href="https://unsplash.com/s/photos/conner-bowe-hobbit?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
          <p>By <a href="https://dpoint.si">dpoint.si</a></p>
        </div>
      </div>
    </div>
  );
}

export default App;
